import { ID, Response } from '../../../../../../_metronic/helpers'

type memoryMaker = {
  [key: string]: any;
};

export type Event = {
  id?: string
  name?: string
  avatar?: any
  dateCreated?: string
  updatedOn?: string
  startDate?: any
  endDate?: any
  parent?: any
  clients?: any
  associatedCollections?: any
  templates?: Array<string>
  memoryMakers?: memoryMaker[]
  projectNo?: string
  qrCards?: boolean
  isIntro?: boolean
  intro?: string
  isForm?: boolean
  form?: string
  isMail?: boolean//sendMicrositeLink2
  sendgrid?: string//sendMicrositeLink2
  wixMail?: string//sendMicrositeLink2
  mailParagraph?: string//send in mail
  isDooh?: boolean
  dooh?: string
  mailParagraphDooh?: string
  sendgridDooh?: string
  quitclaim?: string
  isMicrositeDefault?: boolean
  isMicrosite?: boolean//sendMicrositeLink2
  microsite?: string//wix router - send in mail
  micrositeBeta?: string//wix router
  micrositeDate?: any//send in mail
  micrositeName?: string//send in mail
  exportFolder?: string//lucy
  isAnalytics?: boolean
  embedCode?: string
  info?: string
  tags?: Array<string>
  zearch?: Array<string>
}

export type EventsQueryResponse = Response<Array<Event>>

export const initialEvent: Event = {
  avatar: '',
  name: '',
  mailParagraph: '',
  info: '',
  endDate: ''
}
